@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Kanit";
  src: url("assets/fonts/Kanit/Kanit-Light.ttf");
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Bold.ttf");
  font-weight: 800;
}

* {
  font-family: Noto_Sans_Thai_Looped, serif;
  font-size: 14px;
}

html, body {
  background-color: #f9f9f9;
}

body[data-default-font-size='14px'] #content * {
  font-size: 14px;
}

body[data-default-font-size='16px'] #content * {
  font-size: 16px;
}

body[data-default-font-size='18px'] #content * {
  font-size: 18px;
}

.table {
  font-size: 0.825rem;

  th {
    font-weight: 500;
  }
}


.form-control-plaintext {
  font-size: inherit;
  border-bottom: 2px dotted #e4e7ea;
}

.color-header {
  background: #5e8ef4 !important;
}

.ui-require:after {
  content: "*" !important;
  color: #DC3545FF !important;
  display: contents !important;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
  overflow-y: hidden;

}

body {
  overflow-y: hidden;
}
