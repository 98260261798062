/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import 'custom';
@import 'custom_button';
@import 'custom_table';
@import 'validate';
@import 'custom_forms';
@import "custom_toast";
@import "leaflet.locatecontrol/dist/L.Control.Locate.css";

.bg {
  height: 100vh;
  width: 100% !important;
  background-image: url("./assets/images/Background.png");
  background-size: cover;
}

.custom-bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.custom-bg-danger {
  color: #f21378 !important;
  background-color: #fde1ee !important;
}

.btn-default,
.btn-default:active,
.btn-default:focus {
  transition: .4s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  border: 1px solid #FFF;
}
