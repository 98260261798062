.btn-green{
  --bs-btn-color: #fff;
  --bs-btn-bg: #56CA00;
  --bs-btn-border-color: #56CA00;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #285c00;
  --bs-btn-hover-border-color: #285c00;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
}

.btn-outline-blue {
  --bs-btn-color: #8daff7;
  --bs-btn-border-color: #8daff7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8daff7;
  --bs-btn-hover-border-color: #8daff7;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8daff7;
  --bs-btn-active-border-color: #8daff7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8daff7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8daff7;
  --bs-gradient: none;
}

.btn-custom {
  min-width: 120px;
}

.btn-gray{
  --bs-btn-color: #454649;
  --bs-btn-bg: #dde0e8;
  --bs-btn-border-color: #dde0e8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #454440;
  --bs-btn-hover-border-color: #454440;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
}

.btn-red{
  --bs-btn-color: #fff;
  --bs-btn-bg: #FF4C51;
  --bs-btn-border-color: #FF4C51;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #912b2e;
  --bs-btn-hover-border-color: #912b2e;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
}
