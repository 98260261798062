.col-form-label {
  font-weight: 500;
  color: #322c2c;
}

.ui-require:after {
  content: " * ";
  color: $danger;
  display: contents;
}

//.col-form-label:not(.ui-require):after {
//  content: " : ";
//  display: contents;
//}
.form-control {
  //color: var(--bs-body-color);
  //background-color: var(--bs-body-bg);
  //border-color: #86b7fe;
  border-color: #ccc;
  outline: 0;
  //box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control-plaintext {
  font-size: inherit;
  border-bottom: 2px dotted #e4e7ea !important;
  color: #212529 !important;
  line-height: 1rem;
}

.col-form-label-plaintext {
  line-height: 1rem;
}

.form-control-plaintext:focus {
  outline-style: none;
  box-shadow: none;
  color: #212529 !important;
}

//checkbox
.form-check:not(.form-switch) .form-check-label {
  height: 28px;
  padding-top: 0.3rem !important;
  padding-left: 0.8rem;
  display: block;
}

.form-check:not(.form-switch) {
  input[type=checkbox] {
    width: 25px;
    height: 25px;

    &:checked {
      background-color: white;
      border-color: rgb(0 0 0 / 25%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
    }
  }

  input[type=radio] {
    width: 25px;
    height: 25px;

    &:checked {
      background-color: white;
      border-color: rgb(0 0 0 / 25%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
    }
  }

  .form-check-label {
    height: 28px;
    padding-top: 0.5rem;
    padding-left: 0.8rem;
    display: block;
  }

  input.form-check-input[type=checkbox] {
    width: 25px;
    height: 25px;

    &:checked {
      background-color: white;
      border-color: rgb(0 0 0 / 25%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
    }
  }

  input.form-check-input[type=radio] {
    width: 25px;
    height: 25px;

    &:checked {
      background-color: white;
      border-color: rgb(0 0 0 / 25%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
    }
  }
}

.table-checkbox {
  width: 25px !important;
  height: 25px !important;
  font-size: 0.825rem !important;
}

//end checkbox

input:not([role='switch']):not([autocomplete]):not([readonly]):not(.form-check-input):disabled,
select:disabled,
textarea:not([readonly]):disabled,
.input-group input:disabled {
  background-color: #f9f9f9 !important;
}
